import React, { Suspense, lazy, useState } from "react";

import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "bootstrap/dist/css/bootstrap.css";
import "assets/scss/now-ui-dashboard.scss?v1.5.0";
import "assets/css/demo.css";
import LoadingApp from "./assets/images/LoadingApp.gif";

import { isLogin,isLoginParent,isLoginTeacher } from "utils/session";

const LoginUser = lazy(() => import("./views/LoginUser"));
const CreateSchool = lazy(() => import("./views/CreateSchool"));
const LoginAdmin = lazy(() => import("./views/LoginAdmin"));
const AdminLayout = lazy(() => import("./layouts/Admin"));

const DashBoardParentLayout = lazy(() => import("./layouts/DashboardParent"));
const DashboardTeachersLayout = lazy(() => import("./layouts/DashBoardTeachers"));

import "i18n/config";
//import "firebase/config";
const isUserLogin = isLogin();

const isParentLogin = isLoginParent();
const isTeacherLogin = isLoginTeacher();

function App() {
  const [isTokenFound, setTokenFound] = useState(false);
  //getToken(setTokenFound);
  return (
    <BrowserRouter basename={"/app"}>
      <Suspense
        fallback={
          <div style={{ width: "100%", height: "2500%", textAlign: "center" }}>
            <img
              style={{
                margin: "auto",
                alignItems: "center",
                marginTop: "200px",
              }}
              width={250}
              height={250}
              src={LoadingApp}
            />
          </div>
        }
      >
        <Switch>
          <Route path="/createSchool" component={CreateSchool} />
          <Route path="/loginUser" component={LoginUser} />
          <Route path="/loginAdmin" component={LoginAdmin} />
          <Route path="/admin" render={(props) => <AdminLayout {...props} />} />

          <Route path="/dashboard_parent" render={(props) => <DashBoardParentLayout {...props} />} />
          <Route path="/dashboard_teachers" render={(props) => <DashboardTeachersLayout {...props} />} />

          {isUserLogin && <AdminLayout />}
          {isTeacherLogin && <DashboardTeachersLayout />}
          {isParentLogin && <DashBoardParentLayout />}
          {!isUserLogin && <LoginAdmin />}
          {!isTeacherLogin && <LoginUser />}
          {!isTeacherLogin && <LoginUser />} 

        
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
