import i18n from "i18next";


import { initReactI18next } from 'react-i18next'

import common_fr from "./fr/common.json";
import common_en from "./en/common.json";

const resources = {
    en: {
        translation: common_en,
    },
    fr: {
        translation: common_fr,
    },
};

i18n.use(initReactI18next)
    .init({
        resources,
        lng:"fr", //default language
        keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
    }).then(r => {});

export default i18n;