import {
  SESSION_BUSINESS_NAME,
  SESSION_BUSINESS_KEY,
  SESSION_TOKEN_DURATION,
  SESSION_USERNAME_KEY,
  SESSION_TOKEN_NAME,
  SESSION_USERNAME_NAME,
  SESSION_BUSINESS_ID,
  USER_PREFS,
  USER,
  USER_ID,
  SESSION_BUSINESSES,
  SESSION_CLASSROOMS,
  SESSION_EMPLOYEES,
  SESSION_EMPLOYE,
  SESSION_STUDENTS,
  SESSION_STUDENTS1,
  SESSION_STUDENTS2,
  SESSION_STUDENTS3,
  SESSION_STUDENTS4,
  SESSION_STUDENT,
  SESSION_LESSONS,
  SESSION_LESSON,
  SESSION_BUSINESS_SCHOOL_TYPE,
  SESSION_BULLETINS,
  SESSION_LESSON_NOTES,
  SESSION_LESSON_NOTES1,
  SESSION_LESSON_NOTES2,
  SESSION_LESSON_NOTES3,
  SESSION_LESSON_NOTES4,
  SESSION_CURRENT_BUSINESS,
  SESSION_IS_MUTIPLES_SCHOOLS,
  SESSION_PAYMENTS,
  SESSION_CLAIMINGS,
  SESSION_OFFLINE_MODE,
  SESSION_ANNONCES,
  SESSION_ATTENDANCES,
  SESSION_HOMEWORKS,
  SESSION_LOGO_IMAGE,
  SESSION_BREAKING_NEWS,
  SESSION_QUIZ,
  SESSION_BABILLARD,
  SESSION_DOCUMENT,
  SESSION_CHATS,
  SESSION_TOPICS,
} from "config";
import Cookies from "universal-cookie";
import LZString from "lz-string";
import CryptoJS from "crypto-js";
const cookies = new Cookies();

const { v4: uuidv4 } = require("uuid");

function decryptText(message) {
  let bytes;
  try {
    bytes = CryptoJS.AES.decrypt(message, process.env.REACT_APP_API_KEY);
    ////console.log(bytes)
    const decrypted = bytes.toString(CryptoJS.enc.Utf8);

    return decrypted;
  } catch (err) {
    ////console.log('UNABLE TO DECIPHER',err)
  }

  return null;
}

function encriptText(text) {
  let password = process.env.REACT_APP_API_KEY;
  const ciphertext = CryptoJS.AES.encrypt(
    CryptoJS.enc.Utf8.parse(text),
    password
  ).toString();

  return ciphertext;
}

export function setBusinessKey(businessKey) {
  localStorage.setItem(SESSION_BUSINESS_KEY, encriptText(businessKey));
}

export function setBusinessName(businessName) {
  localStorage.setItem(SESSION_BUSINESS_NAME, encriptText(businessName));
}

export function setBusinessSchoolType(schoolTyp) {
  localStorage.setItem(SESSION_BUSINESS_SCHOOL_TYPE, encriptText(schoolTyp));
}

export function setBusinessId(businessId) {
  localStorage.setItem(SESSION_BUSINESS_ID, encriptText(businessId));
}

export function setUsername(username) {
  localStorage.setItem(SESSION_USERNAME_NAME, encriptText(username));
}

export function setUserId(userId) {
  localStorage.setItem(USER_ID, encriptText(userId));
}

export function getBusinessSchoolType() {
  try {
    let business = JSON.parse(
      decryptText(localStorage.getItem(SESSION_CURRENT_BUSINESS))
    );
    if (business === null || undefined) {
      return decryptText(localStorage.getItem(SESSION_BUSINESS_SCHOOL_TYPE));
    }
    return business.school_type;
  } catch (e) {
    return decryptText(localStorage.getItem(SESSION_BUSINESS_SCHOOL_TYPE));
  }
}

export function getUserId() {
  return decryptText(localStorage.getItem(USER_ID));
}

export function isLogin() {
  if (localStorage.getItem(USER_ID) != null) {
    return true;
  } else {
    return false;
  }
}

export function isLoginParent() {
  if (localStorage.getItem(SESSION_STUDENT) != null) {
    return true;
  } else {
    return false;
  }
}

export function isLoginTeacher() {
  if (localStorage.getItem(SESSION_EMPLOYE) != null) {
    return true;
  } else {
    return false;
  }
}

export function getBusinessId() {
  try {
    let business = JSON.parse(
      decryptText(localStorage.getItem(SESSION_CURRENT_BUSINESS))
    );
    if (business === null || undefined) {
      return decryptText(localStorage.getItem(SESSION_BUSINESS_ID));
    }
    return business.business_id;
  } catch (e) {
    return decryptText(localStorage.getItem(SESSION_BUSINESS_ID));
  }

  return null;
}

export function getBusinessName() {
  try {
    let business = JSON.parse(
      decryptText(localStorage.getItem(SESSION_CURRENT_BUSINESS))
    );
    if (business === null || undefined) {
      return decryptText(localStorage.getItem(SESSION_BUSINESS_NAME));
    }
    return business.name;
  } catch (e) {
    return decryptText(localStorage.getItem(SESSION_BUSINESS_NAME));
  }
}

export function getBusiness() {
  try {
    let business = JSON.parse(
      decryptText(localStorage.getItem(SESSION_CURRENT_BUSINESS))
    );

    if (business != null && business != undefined) return business;

    let business_json = JSON.parse(
      decryptText(localStorage.getItem(SESSION_BUSINESSES))
    );
    if (business_json === null) {
      return null;
    }
    business = JSON.parse(business_json);
    if (Array.isArray(business)) {
      return business[0];
    }
  } catch (e) {}

  return null;
}

export function getUsername() {
  return decryptText(localStorage.getItem(SESSION_USERNAME_KEY));
}

export function getSchoolTyp() {
  let schoolTyp = getBusinessSchoolTyp();

  if (schoolTyp?.toLowerCase().includes("prim")) {
    //primary school
    return 1;
  } else if (
    schoolTyp?.toLowerCase().includes("secon") &&
    (schoolTyp?.toLowerCase().includes("high") ||
      schoolTyp?.toLowerCase().includes("lyc"))
  ) {
    //high school
    return 3;
  } else if (
    schoolTyp?.toLowerCase().includes("secon") &&
    schoolTyp?.toLowerCase().includes("col")
  ) {
    //college
    return 4;
  } else if (
    schoolTyp?.toLowerCase().includes("for") ||
    schoolTyp?.toLowerCase().includes("train")
  ) {
    //training center
    return 5;
  } else if (schoolTyp?.toLowerCase().includes("uni")) {
    //university
    return 6;
  }

  return -1;
}
export function getBusinessSchoolTyp() {
  let schoolTyp = decryptText(
    localStorage.getItem(SESSION_BUSINESS_SCHOOL_TYPE)
  );

  if (schoolTyp != null) {
    return schoolTyp;
  }
  try {
    let business = JSON.parse(
      decryptText(localStorage.getItem(SESSION_CURRENT_BUSINESS))
    );
    if (business === null || undefined) {
      return decryptText(localStorage.getItem(SESSION_BUSINESS_SCHOOL_TYPE));
    }
    return business.school_type;
  } catch (e) {
    return decryptText(localStorage.getItem(SESSION_BUSINESS_SCHOOL_TYPE));
  }
}

export function getBusinessKey() {
  try {
    let business = JSON.parse(
      decryptText(localStorage.getItem(SESSION_CURRENT_BUSINESS))
    );
    if (business === null || undefined) {
      return decryptText(localStorage.getItem(SESSION_BUSINESS_KEY));
    }
    return business.business_key;
  } catch (e) {
    return decryptText(localStorage.getItem(SESSION_BUSINESS_KEY));
  }
}

export function setToken(appacademiaToken) {
  if (typeof appacademiaToken !== "string") {
    throw new Error("Invalid Token");
  }

  cookies.set(SESSION_TOKEN_NAME, appacademiaToken, {
    path: "/",
    maxAge: SESSION_TOKEN_DURATION,
  });
  localStorage.setItem(SESSION_TOKEN_NAME, appacademiaToken);
}

export function getToken() {
  return cookies.get(SESSION_TOKEN_NAME);
}

export function clearSession() {
  cookies.remove(SESSION_TOKEN_NAME, {
    path: "/",
    maxAge: SESSION_TOKEN_DURATION,
  });
  localStorage.removeItem(SESSION_TOKEN_NAME);
  localStorage.removeItem(SESSION_BUSINESS_NAME);
  localStorage.removeItem(SESSION_BUSINESS_ID);
  localStorage.removeItem(SESSION_BUSINESS_KEY);
  localStorage.removeItem(SESSION_BUSINESSES);
  localStorage.removeItem(SESSION_EMPLOYE);
  localStorage.removeItem(SESSION_EMPLOYEES);
  localStorage.removeItem(SESSION_CLASSROOMS);
  localStorage.removeItem(SESSION_STUDENT);
  localStorage.removeItem(SESSION_STUDENTS);
  localStorage.removeItem(SESSION_STUDENTS1);
  localStorage.removeItem(SESSION_STUDENTS2);
  localStorage.removeItem(SESSION_STUDENTS3);
  localStorage.removeItem(SESSION_STUDENTS4);
  localStorage.removeItem(SESSION_LESSON);
  localStorage.removeItem(SESSION_LESSONS);
  localStorage.removeItem(USER);
  localStorage.removeItem(USER_ID);
  localStorage.removeItem(SESSION_USERNAME_NAME);
  localStorage.removeItem(SESSION_CURRENT_BUSINESS);
  localStorage.removeItem(SESSION_BUSINESS_SCHOOL_TYPE);
  localStorage.removeItem(SESSION_LESSON_NOTES);
  localStorage.removeItem(SESSION_BULLETINS);
  localStorage.removeItem(SESSION_IS_MUTIPLES_SCHOOLS);
  localStorage.removeItem(SESSION_PAYMENTS);
  localStorage.removeItem(SESSION_CLAIMINGS);
  localStorage.removeItem(SESSION_OFFLINE_MODE);
  localStorage.removeItem(SESSION_ANNONCES);
  localStorage.removeItem(SESSION_ATTENDANCES);
  localStorage.removeItem(SESSION_HOMEWORKS);
  localStorage.removeItem(SESSION_LOGO_IMAGE);
  localStorage.removeItem(SESSION_BREAKING_NEWS);
  localStorage.removeItem(SESSION_QUIZ);
  localStorage.removeItem(SESSION_BABILLARD);
  localStorage.removeItem(SESSION_DOCUMENT);
  localStorage.removeItem(SESSION_CHATS);
  localStorage.removeItem(SESSION_TOPICS);
}

export function setTopics(docs) {
  localStorage.setItem(SESSION_TOPICS, encriptText(JSON.stringify(docs)));
}
export function getTopics() {
  return JSON.parse(decryptText(localStorage.getItem(SESSION_TOPICS)));
}

export function setChats(docs) {
  localStorage.setItem(SESSION_CHATS, encriptText(JSON.stringify(docs)));
}
export function getChats() {
  return JSON.parse(decryptText(localStorage.getItem(SESSION_CHATS)));
}

export function setDocument(docs) {
  localStorage.setItem(SESSION_DOCUMENT, encriptText(JSON.stringify(docs)));
}
export function getDocument() {
  return JSON.parse(decryptText(localStorage.getItem(SESSION_DOCUMENT)));
}
export function setBabillard(barbillard) {
  localStorage.setItem(
    SESSION_BABILLARD,
    encriptText(JSON.stringify(barbillard))
  );
}
export function getBabillard() {
  return JSON.parse(decryptText(localStorage.getItem(SESSION_BABILLARD)));
}

export function setQuiz(quiz) {
  localStorage.setItem(SESSION_QUIZ, encriptText(JSON.stringify(quiz)));
}
export function getQuiz() {
  return JSON.parse(decryptText(localStorage.getItem(SESSION_QUIZ)));
}

export function setBreakingNews(news) {
  localStorage.setItem(
    SESSION_BREAKING_NEWS,
    encriptText(JSON.stringify(news))
  );
}
export function getBreakingNews() {
  return JSON.parse(decryptText(localStorage.getItem(SESSION_BREAKING_NEWS)));
}

export function setLogoImg(logo) {
  localStorage.setItem(SESSION_LOGO_IMAGE, logo);
}

export function getLogoImg() {
  return localStorage.getItem(SESSION_LOGO_IMAGE);
}

export function setThemeType(themeType) {
  localStorage.setItem(USER_PREFS.THEME_KEY, themeType);
}

export function getThemeType() {
  return localStorage.getItem(USER_PREFS.THEME_KEY);
}

export function setOfflineMode(mode) {
  localStorage.setItem(SESSION_OFFLINE_MODE, encriptText(mode));
}
export function getOfflineMode() {
  const mode = decryptText(localStorage.getItem(SESSION_OFFLINE_MODE));
  if (mode && mode === "1") {
    return true;
  }
  //if there is no internet, please set offline
  if (!navigator.onLine) {
    localStorage.setItem(SESSION_OFFLINE_MODE, encriptText("1"));
    return true;
  }
  return false;
}

export function setUser(user) {
  localStorage.setItem(USER, encriptText(JSON.stringify(user)));
}
export function getUser() {
  return JSON.parse(decryptText(localStorage.getItem(USER)));
}

export function setBusinesses(businesses) {
  localStorage.setItem(
    SESSION_BUSINESSES,
    encriptText(JSON.stringify(businesses))
  );
}

export function setClassRooms(classrooms) {
  localStorage.setItem(
    SESSION_CLASSROOMS,
    encriptText(JSON.stringify(classrooms))
  );
}

export function getClassRooms() {
  return JSON.parse(decryptText(localStorage.getItem(SESSION_CLASSROOMS)));
}

export function setLessonNotes(lessonNotes) {
  var compressed = LZString.compressToUTF16(JSON.stringify(lessonNotes));
  localStorage.setItem(SESSION_LESSON_NOTES, encriptText(compressed));
}

export function getLessonNotes() {
  var decompressed = LZString.decompressFromUTF16(
    decryptText(localStorage.getItem(SESSION_LESSON_NOTES))
  );

  return JSON.parse(decompressed);
}
export function setStudents(students) {
  localStorage.setItem(SESSION_STUDENTS, encriptText(JSON.stringify(students)));
}

export function getStudents() {
  return JSON.parse(decryptText(localStorage.getItem(SESSION_STUDENTS)));
}

export function setStudents1(students) {
  localStorage.setItem(
    SESSION_STUDENTS1,
    encriptText(JSON.stringify(students))
  );
}

export function getStudents1() {
  return JSON.parse(decryptText(localStorage.getItem(SESSION_STUDENTS1)));
}

export function setStudents2(students) {
  localStorage.setItem(
    SESSION_STUDENTS2,
    encriptText(JSON.stringify(students))
  );
}

export function getStudents2() {
  return JSON.parse(decryptText(localStorage.getItem(SESSION_STUDENTS2)));
}

export function setStudents3(students) {
  localStorage.setItem(
    SESSION_STUDENTS3,
    encriptText(JSON.stringify(students))
  );
}

export function getStudents3() {
  return JSON.parse(decryptText(localStorage.getItem(SESSION_STUDENTS3)));
}
export function setStudents4(students) {
  localStorage.setItem(
    SESSION_STUDENTS4,
    encriptText(JSON.stringify(students))
  );
}

export function getStudents4() {
  return JSON.parse(decryptText(localStorage.getItem(SESSION_STUDENTS4)));
}

export function setIsMultiple(is_multiple) {
  localStorage.setItem(SESSION_IS_MUTIPLES_SCHOOLS, encriptText(is_multiple));
}

export function isMultipleBusinessExist() {
  let isMulti = decryptText(localStorage.getItem(SESSION_IS_MUTIPLES_SCHOOLS));
  if (isMulti != null) {
    return isMulti;
  }
  let business_json = JSON.parse(
    decryptText(localStorage.getItem(SESSION_BUSINESSES))
  );
  try {
    let business = JSON.parse(business_json);
    if (Array.isArray(business) && business.length > 0) {
      return true;
    }
  } catch (e) {}

  return false;
}
export function setCurrentBusiness(business) {
  localStorage.setItem(
    SESSION_CURRENT_BUSINESS,
    encriptText(JSON.stringify(business))
  );
}

export function getCurrentBusiness() {
  try {
    let current_bus = JSON.parse(
      decryptText(localStorage.getItem(SESSION_CURRENT_BUSINESS))
    );
    if (current_bus != null) {
      return current_bus;
    }
    let business_json = JSON.parse(
      decryptText(localStorage.getItem(SESSION_BUSINESSES))
    );

    let business = JSON.parse(business_json);
    if (Array.isArray(business)) {
      return business[0];
    }
  } catch (e) {}

  return null;
}

export function getClassRoomsNumber() {
  try {
    const classrooms = JSON.parse(
      decryptText(localStorage.getItem(SESSION_CLASSROOMS))
    );
    let localclassrooms = JSON.parse(classrooms);
    if (localclassrooms != null && Array.isArray(localclassrooms)) {
      return localclassrooms?.length;
      /* if((getBusinessKey()=== "35560225" || getBusinessKey() === "39387534")){
        
        if(filterYear && arr.academic_year && arr?.academic_year === filterYear){
          return localclassrooms.length;
        }
        else{
          return 0;
        }
      }

      if(filterYear && arr.academic_year && arr?.academic_year === filterYear){
        return locallessons.length;
      }
      else{
        return 0;
      }
    

      if(filterYear && arr.academic_year && arr?.academic_year === filterYear){
        return localclassrooms.length;
      }
      else{
        return 0;
      }
      return localclassrooms.length; */
    } else {
      return 0;
    }
  } catch (e) {}

  return 0;
}

export function setEmployees(employees) {
  localStorage.setItem(
    SESSION_EMPLOYEES,
    encriptText(JSON.stringify(employees))
  );
}

export function getEmployees() {
  return JSON.parse(decryptText(localStorage.getItem(SESSION_EMPLOYEES)));
}

export function getEmployeesNumber(filterYear) {
  try {
    const employees = JSON.parse(
      decryptText(localStorage.getItem(SESSION_EMPLOYEES))
    );
    let localemployees = JSON.parse(employees);
    if (localemployees != null && Array.isArray(localemployees)) {
      return localemployees?.length;
      /*  const count = localemployees.filter((arr) => {

        if((getBusinessKey()=== "35560225" || getBusinessKey() === "39387534")){
        
          if(filterYear && arr.academic_year && arr?.academic_year === filterYear){
            return true;
          }
          else{
            return false;
          }
        }
        if (arr?.academic_year === filterYear || !arr.academic_year) {
          return true;
        }

        return false;
      }).length;

      return count; */
    } else {
      return 0;
    }
  } catch (e) {}

  return 0;
}

export function setEmploye(employe) {
  localStorage.setItem(SESSION_EMPLOYE, encriptText(JSON.stringify(employe)));
}

export function getEmploye() {
  return JSON.parse(decryptText(localStorage.getItem(SESSION_EMPLOYE)));
}

export function getStudentsNumber(filterYear) {
  try {
    const students = JSON.parse(
      decryptText(localStorage.getItem(SESSION_STUDENTS))
    );
    let localmystudents = JSON.parse(students);
    if (localmystudents != null && Array.isArray(localmystudents)) {
      return localmystudents?.length;
      /*  const count = localmystudents.filter((arr) => {
        if(getBusinessKey()=== "35560225" || getBusinessKey() === "39387534"){
          if(filterYear && arr.academic_year && arr?.academic_year === filterYear){
            return true;
          }
          else{
            return false;
          }
        }
        if (filterYear === undefined || !filterYear || arr?.academic_year === filterYear || !arr.academic_year) {
          return true;
        }

        return false;
      }).length;

      return count; */
    } else {
      return 0;
    }
  } catch (e) {}

  return 0;
}

export function getStudentsNumberNew(filterYear) {
  try {
    const students = JSON.parse(
      decryptText(localStorage.getItem(SESSION_STUDENTS))
    );
    let localmystudents = JSON.parse(students);
    if (localmystudents != null && Array.isArray(localmystudents)) {
      return localmystudents?.length;
      // const count = localmystudents.filter((arr) => {

      //   if((getBusinessKey()=== "35560225" || getBusinessKey() === "39387534")){
      //     if(arr?.isNewStudent){
      //       if(filterYear && arr.academic_year && arr?.academic_year === filterYear){
      //         return true;
      //       }
      //       else{
      //         return false;
      //       }
      //     }

      //     else{
      //       return false;
      //     }
      //   }

      //   if (arr?.isNewStudent && (!filterYear || filterYear === undefined || arr?.academic_year === filterYear || !arr.academic_year)) {
      //     return true;
      //   }

      //   return false;
      // }).length;
      // return count;
    } else {
      return 0;
    }
  } catch (e) {}

  return 0;
}
export function getStudentsNumberOld(filterYear) {
  try {
    const students = JSON.parse(
      decryptText(localStorage.getItem(SESSION_STUDENTS))
    );
    let localmystudents = JSON.parse(students);
    if (localmystudents != null && Array.isArray(localmystudents)) {
      return localmystudents?.length;
      /*   const count = localmystudents.filter((arr) => {

        
        if((getBusinessKey()=== "35560225" || getBusinessKey() === "39387534")){
        
          if(arr?.isNewStudent ){
            return false;
          }
          else{

          
            if(filterYear && arr.academic_year && arr?.academic_year === filterYear){
              return true;
            }
            else{
          
                return false;
              }
            }

            return false;
          }
        
        
        if (!arr?.isNewStudent  && (!filterYear || arr?.academic_year === filterYear || !arr.academic_year)) {
          return true;
        }

        return false;
      }).length; */
      return count;
    } else {
      return 0;
    }
  } catch (e) {}

  return 0;
}

export function getPayementsNumber(filterYear) {
  try {
    const payments = JSON.parse(
      decryptText(localStorage.getItem(SESSION_PAYMENTS))
    );
    let localmypayments = JSON.parse(payments);
    if (localmypayments != null && Array.isArray(localmypayments)) {
      /*  const count = localmypayments.filter((arr) => {
        if(getBusinessKey()=== "35560225" || getBusinessKey() === "39387534"){
          if(filterYear && arr.academic_year && arr?.academic_year === filterYear){
            return true;
          }
          else{
            return false;
          }
        }
        if ((filterYear === undefined || !filterYear || (filterYear && arr.academic_year && arr?.academic_year === filterYear) || !arr.academic_year)) {
          return true;
        }

        return false;
      }).length; */
      const count = localmypayments?.length;
      return count;
    } else {
      return 0;
    }
  } catch (e) {}

  return 0;
}

export function setStudent(student) {
  localStorage.setItem(SESSION_STUDENT, encriptText(JSON.stringify(student)));
}

export function getStudent() {
  return JSON.parse(decryptText(localStorage.getItem(SESSION_STUDENT)));
}

export function getBusinesses() {
  return JSON.parse(decryptText(localStorage.getItem(SESSION_BUSINESSES)));
}

export function setAttendances(attendances) {
  localStorage.setItem(
    SESSION_ATTENDANCES,
    encriptText(JSON.stringify(attendances))
  );
}

export function getAttendances() {
  return JSON.parse(decryptText(localStorage.getItem(SESSION_ATTENDANCES)));
}

export function setHomeWorks(homeworks) {
  localStorage.setItem(
    SESSION_HOMEWORKS,
    encriptText(JSON.stringify(homeworks))
  );
}

export function getHomeWorks() {
  return JSON.parse(decryptText(localStorage.getItem(SESSION_HOMEWORKS)));
}

export function setBulletins(bulletins) {
  localStorage.setItem(
    SESSION_BULLETINS,
    encriptText(JSON.stringify(bulletins))
  );
}

export function getBulletins() {
  return JSON.parse(decryptText(localStorage.getItem(SESSION_BULLETINS)));
}

export function getBulletinsNumber(filterYear) {
  try {
    const bulletins = JSON.parse(
      decryptText(localStorage.getItem(SESSION_BULLETINS))
    );
    let localbulletins = JSON.parse(bulletins);
    if (localbulletins != null && Array.isArray(localbulletins)) {
      return localbulletins?.length;
      /*   const count = localbulletins.filter((arr) => {
          if(getBusinessKey()=== "35560225" || getBusinessKey() === "39387534"){
          if(filterYear && arr.academic_year && arr?.academic_year === filterYear){
            return true;
          }
          else{
            return false;
          }
        }
        if ((!filterYear || arr?.academic_year === filterYear || !arr.academic_year)) {
          return true;
        }

        return false;
      }).length;
      return count; */
    } else {
      return 0;
    }
  } catch (e) {}

  return 0;
}

export function setLessons(lessons) {
  localStorage.setItem(SESSION_LESSONS, encriptText(JSON.stringify(lessons)));
}

export function getLessons() {
  return JSON.parse(decryptText(localStorage.getItem(SESSION_LESSONS)));
}

export function setPayments(payments) {
  localStorage.setItem(SESSION_PAYMENTS, encriptText(JSON.stringify(payments)));
}

export function getPayments() {
  return JSON.parse(decryptText(localStorage.getItem(SESSION_PAYMENTS)));
}

export function setClaimings(claimings) {
  localStorage.setItem(
    SESSION_CLAIMINGS,
    encriptText(JSON.stringify(claimings))
  );
}

export function getClaimings() {
  return JSON.parse(decryptText(localStorage.getItem(SESSION_CLAIMINGS)));
}

export function setSchoolAnnouncements(annonces) {
  localStorage.setItem(SESSION_ANNONCES, encriptText(JSON.stringify(annonces)));
}

export function getSchoolAnnouncements() {
  return JSON.parse(decryptText(localStorage.getItem(SESSION_ANNONCES)));
}

export function getLessonsNumber() {
  try {
    const lessons = JSON.parse(
      decryptText(localStorage.getItem(SESSION_LESSONS))
    );
    let locallessons = JSON.parse(lessons);
    if (locallessons != null && Array.isArray(locallessons)) {
      return locallessons?.length;
      /*  if((getBusinessKey()=== "35560225" || getBusinessKey() === "39387534")){
        
        if(filterYear && arr.academic_year && arr?.academic_year === filterYear){
          return locallessons.length;
        }
        else{
          return 0;
        }
      } */
    } else {
      return 0;
    }
  } catch (e) {}

  return 0;
}

export function setLesson(lesson) {
  localStorage.setItem(SESSION_LESSON, encriptText(JSON.stringify(lesson)));
}

export function getLesson() {
  return JSON.parse(decryptText(localStorage.getItem(SESSION_LESSON)));
}

export function getUnique() {
  return uuidv4();
}
